import moment from 'moment';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const DateFromNow = ({dateTime}) => {

    const [dateFromNow, setDateFromNow] = useState("");
    
    const setRunTime = () => {
    //   for (const bot of botsData) {
    //     bot.runTime =  moment(new Date(bot.createdAt)).fromNow();
    //     bot.lastModTime =  moment(new Date(bot.lastModified)).fromNow();
    //   }
        if (dateTime) {
            setDateFromNow(moment(new Date(dateTime)).fromNow());
        }
        
    }

    useEffect(() => {
        if (dateTime) {
            setRunTime();
            setInterval(setRunTime, 10000);
        }
    }, [dateTime])
        
    return <>{dateFromNow || " - "}</>
        
}

DateFromNow.propTypes = {
    dateTime: PropTypes.string,
}

export default DateFromNow;