import { takeEvery, fork, put, all, call } from "redux-saga/effects"

import { GET_RESULT } from "./actionTypes"
import { getResultSuccess, getResultError } from "./actions"
import { getResult } from "helpers/results_api"


function* onGetResult({payload: id}) {
  try {
    const response = yield call(getResult, id)
    yield put(getResultSuccess(response))      
  } catch (error) {
    yield put(getResultError(error))
  }
}

function* resultsSaga() {
  yield takeEvery(GET_RESULT, onGetResult);
}

export default resultsSaga
