import { Badge } from "reactstrap";
import PropTypes from 'prop-types';
import Spinner from "components/Common/Spinner";

const BotIcon = ({bot, onClick}) => {
    if (!bot) {
        return <></>//<Spinner isButton={true}/>
    }

    return <div className="position-relative avatar-lg mb-1" onClick={() => {onClick && onClick()}}>
        <img
            src={require(`/src/assets/images/bots/thumbnails/ea-${bot.eaId}.png`)}
            className={`z-0 rounded-4 img-thumbnail border-dark ${onClick && "hover-select"}`}
            alt={bot.name}
            />
        <Badge color="dark" className="p-2 mb-n2 me-n2 position-absolute bottom-0 end-0 z-1">
            {bot.symbol && bot.symbol}
            {bot.period && <span className="ms-1">({bot.period})</span>}
        </Badge>
    </div>
}

BotIcon.propTypes = {
    bot: PropTypes.object,
    onClick: PropTypes.func
}

export default BotIcon;