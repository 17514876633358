import Choose from "../pages/BotTrader/Bots/New/choose";
import Broker from "../pages/BotTrader/Bots/New/broker";
import UserProfile from "../pages/Authentication/UserProfile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

import PagesPricing from "../pages/BotPricing/pages-pricing";
import PagesPayment from "../pages/BotPricing/pages-payment";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesPaymentSuccess from "pages/BotPricing/pages-payment-success";
import BotsPage from "pages/BotTrader/Bots/BotsPage";
import SetUpPage from "pages/Onboarding/SetUpPage";
import DashboardPage from "pages/Dashboard/DashboardPage";
import Finish from "../pages/BotTrader/Bots/New/finish";
import DownloadBotPage from "../pages/BotTrader/Bots/DowloadBotPage";
import Chat from "pages/Chat/Chat";
import PagesFaqs from "pages/Utility/pages-faqs";
import UnsubPage from "pages/User/UnsubPage";
import BrokersPage from "pages/Onboarding/BrokersPage";
import AffiliatesPage from "pages/Affiliates/AffiliatesPage";
import AffiliateRefPage from "pages/Affiliates/AffiliateRefPage";
import ResultPage from "pages/Results/ResultPage";

const authProtectedRoutes = [

  { path: "/bots", component: <BotsPage /> },
  { path: "/bots/new/choose", component: <Choose />},
  { path: "/bots/new/broker", component: <Broker />},
  { path: "/bots/new/finish/:botId", component: <Finish />},
  { path: "/payment/:productId/:priceId", component: <PagesPayment /> },
  { path: "/bots/download/:botId", component: <DownloadBotPage/>},

  { path: "/setup/:stepId?", component: <SetUpPage /> },

  { path: "/profile", component: <UserProfile /> },
  { path: "/pricing", component: <PagesPricing /> },
  { path: "/brokers", component: <BrokersPage /> },
  { path: "/payment/:productId/:priceId/:priceType", component: <PagesPayment /> },
  { path: "/payment-success/:productId/:priceId", component: <PagesPaymentSuccess /> },
  { path: "/chat/:channelId?", component: <Chat /> },
  { path: "/affiliates", component: <AffiliatesPage/>},
  { path: "/result/:id", component: <Pages404/>},
  { path: "/", component: <DashboardPage /> },
];

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
  { path: "/ref/:referralCode", component: <AffiliateRefPage /> },


  { path: "/unsub/:userId", component: <UnsubPage /> },

  // TODO
  // { path: "/faq", component: <PagesFaqs /> },
  { path: "/*", component: <Pages404 /> },
  // { path: "/500", component: <Pages500 /> },


];

export { authProtectedRoutes, publicRoutes };
