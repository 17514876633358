import React from "react"
import { Spinner as RsSpinner } from "reactstrap";

const SpinnerInline = ({isSmall = false, className = ""}) => {
    return (
        <div className={`d-flex justify-content-center ${className}`}>
            {isSmall ? <RsSpinner size="sm"/> : <RsSpinner/>}

        </div>     
    )
}

export default SpinnerInline;