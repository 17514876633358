import { isNotNull } from "helpers/util";
import { FormattedNumber } from 'react-intl'

const CurrencyFormat = ({value, currency = undefined, isNull = undefined, noTrailingZeros = false, isProfitLoss = false}) => {

  const isPositive = value >= 0;
  
  
  if ((isNull != undefined && isNull()) || !isNotNull(value) ) {
    return <div className="px-2">-</div>
  }

  const nf = <FormattedNumber value={value} style="currency" trailingZeroDisplay={noTrailingZeros ? "stripIfInteger" : "auto"} 
  currency={currency === undefined ? "USD" : currency} />;

  if (!isProfitLoss) {
    return nf;
  }
  const style = isPositive ? {color: "green"} : {color: "#f46a6a"};
  return (
    <span style={{}}>
      {isPositive ? "+" : ""} 
      {nf}
      {isPositive 
      ? <i className="mdi mdi-arrow-up text-success"/>
      : <i className="mdi mdi-arrow-down text-danger"/>}
    </span>
  );
}

export default CurrencyFormat;