import React from "react"
import { Spinner as RsSpinner } from "reactstrap";

const Spinner = ({isButton = false, status = null, size = "sm"}) => {
    return (
        <React.Fragment>
            {
                isButton 
                    ? <RsSpinner type={status === "REFRESHING" ? "grow" : "border"} className="ms-2" size={size}/>
                    : <RsSpinner  
                        style={{
                            height: '4rem',
                            width: '4rem'
                        }} 
                        color="primary" 
                        className='position-absolute top-30 start-50'>
                        Loading...
                    </RsSpinner>
            }
        </React.Fragment>
    )
}

export default Spinner;