import { 
  GET_RESULT_SUCCESS,
  GET_RESULT,
  GET_RESULT_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  success: false,
  loading: true,
  result: null,
};

const results = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESULT:
      state = { ...state, loading: true, error: "" };
      break;
    case GET_RESULT_SUCCESS:
      state = { ...state, result: action.payload, loading: false };
      break;
    case GET_RESULT_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
  }
  return state;
};

export default results;
