import { 
  GET_RESULT,
  GET_RESULT_ERROR,
  GET_RESULT_SUCCESS} from "./actionTypes"

export const getResult = (id: string) => {
  return {
    type: GET_RESULT,
    payload: id
  }
}

export const getResultSuccess = (result) => {
  return {
    type: GET_RESULT_SUCCESS,
    payload: result,
  }
}

export const getResultError = error => {
  return {
    type: GET_RESULT_ERROR,
    payload: error,
  }
}
